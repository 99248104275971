<template>
    <div :class="`section is-${size} has-text-centered empty is-fullheight`">
      <span class="icon is-large">
        <i class="fal fa-2x fa-eye-slash"></i>
      </span>
      <p class="title is-5 has-text-danger">{{ text }}</p>
      <p class="subtitle is-6 has-text-danger">{{ subtext }}</p>
    </div>
</template>

<script>
export default {
  name: 'TableEmpty',
  props: {
    size: {
      type: String,
      required: false,
      default: () => 'large'
    },
    text: {
      type: String,
      required: false,
      default: () => 'No activity found'
    },
    subtext: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>

<style lang="sass" scoped>
.empty
  background: repeating-linear-gradient(45deg, #fff, #fff 10px, #fff 10px, #f5f5f5 20px)
</style>
